<template>
    <div class="saleTable Grade">
        <el-table
            :data="gradeConcrete"
            stripe
        >
            <el-table-column
                label="混疑土升降标号"
            >
                <template slot-scope="scope">
                    <el-select
                        disabled
                        v-model="scope.row.float_floor"
                    >
                        <el-option
                            v-for="item in gradeConcreteOption"
                            :key="item.code"
                            :label="item.name"
                            :value="item.name"
                        >
                        </el-option>
                    </el-select>
                    —
                    <el-select
                        v-model="scope.row.float_upper"
                        placeholder=""
                        :disabled="scope.row.editObj.disabledSel"
                        @change="preserveGrade(scope.row, scope.$index, 'tu')"
                    >
                        <el-option
                            v-for="item in gradeConcreteOption"
                            :key="item.code"
                            :disabled="scope.row.editObj.disabledArr.indexOf(item.name) === -1"
                            :label="item.name"
                            :value="item.name"
                        >
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column
                label="混疑土差价（元）"
            >
                <template slot-scope="scope">
                    每个标号，
                    <div class="biaohao">
                        {{ scope.row.float_type === 1 ? '上浮' : '下降' }}
                    </div>
                    :
                    <input type="text" v-model="scope.row.float_price" @change="preserveGrade(scope.row, scope.$index, 'tu')">
                </template>
            </el-table-column>
            <el-table-column label="操作" :render-header="renderHeaderTwo">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="danger"
                        class="iconfont delete iconshanchu"
                        v-if="scope.$index==gradeConcrete.length-1"
                        @click="deleteGrade(scope.row, scope.$index)"
                        style="margin-left:.7rem;"
                    >
                    </el-button>
                    <!-- && gradeConcrete.length > 1 -->
                    <!-- <el-button
                        size="mini"
                        class="iconfont addTo icontianjia1"
                        v-if="(scope.$index == gradeConcrete.length - 1) && (scope.row.float_upper < gradeConcreteOption.slice(-1)[0].name)"
                        @click="addToGrade(scope.row, scope.$index)"
                    >
                    </el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <el-table
            :data="gradeMortar"
            class="gradeMortar"
            stripe
        >
            <el-table-column
                label="砂浆升降标号"
            >
                <template slot-scope="scope">
                    <el-select
                        disabled
                        v-model="scope.row.float_floor"
                    >
                        <el-option
                            v-for="item in gradeMortarOption"
                            :key="item.value"
                            :label="item.name"
                            :value="item.name"
                        >
                        </el-option>
                    </el-select>
                    —
                    <el-select
                        v-model="scope.row.float_upper"
                        :disabled="scope.row.editObj.disabledSel"
                        placeholder=""
                        @change="preserveGrade(scope.row, scope.$index, 'sha')"
                    >
                        <el-option
                            v-for="item in gradeMortarOption"
                            :key="item.value"
                            :label="item.name"
                            :value="item.name"
                            :disabled="scope.row.editObj.disabledArr.indexOf(item.name) === -1"
                        >
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column
                label="砂浆差价（元）"
            >
                <template slot-scope="scope">
                    每个标号，
                    <div class="biaohao">
                        {{ scope.row.float_type === 1 ? '上浮' : '下降' }}
                    </div>
                    <!-- <el-select
                        disabled
                        v-model="scope.row.float_type"
                        style="width:0.62rem;"
                    >
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select> -->
                    :
                    <input type="text" v-model="scope.row.float_price" @change="preserveGrade(scope.row, scope.$index, 'sha')">
                </template>
            </el-table-column>
            <el-table-column label="操作" :render-header="renderHeader">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="danger"
                        class="iconfont delete iconshanchu"
                        v-if="scope.$index==gradeMortar.length-1"
                        @click="deleteGradeTwo(scope.row, scope.$index)"
                        style="margin-left:.7rem;"
                    >
                    </el-button>
                    <!-- && gradeMortar.length > 1 -->
                    <!-- <el-button
                        size="mini"
                        class="iconfont addTo icontianjia1"
                        v-if="scope.$index==gradeMortar.length-1 && scope.row.float_upper < gradeMortarOption.slice(-1)[0].name"
                        @click="addToGradeTwo(scope.row, scope.$index)"
                    >
                    </el-button> -->
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import _ from 'loadsh';
export default {
    // 销售价格管理-升降标号页
    name: 'grade',
    components: {},
    props: {
        currentData: {
            type: Object,
        },
    },
    data() {
        return {
            deleteId: '',
            // 切换数据新ID
            newAreaid: '',
            // 升降标号数据集合
            tabPageData: [],
            // 当前期数id
            price_id: '',
            // 当前区域id
            area_id: 0,
            options: [{
                value: 1,
                label: '上浮',
            }, {
                value: 2,
                label: '下降',
            }],
            // 升降标号-混泥土数据集合
            gradeConcrete: [],
            // 升降标号-混泥土标号集合
            gradeConcreteOption: [],
            // 升降标号-砂浆数据集合
            gradeMortar: [],
            // 升降标号-砂浆标号集合
            gradeMortarOption: [],
            isRegions: false,
        };
    },
    watch: {
        currentData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                this.isRegions = newVal.isRegion;
                if (newVal.isRegion) {
                    this.newAreaid = newVal.areaid;
                } else {
                    this.newAreaid = 0;
                }
                const initData = newVal.initData;
                if (newVal.initData && initData.SalePriceInfo) {
                    if (initData.SalePriceInfo !== 'null' && initData.SalePriceInfo !== null) {
                        this.price_id = initData.SalePriceInfo.id;
                        if (initData.SalePriceInfo.is_area) {
                            if (initData.AreaList !== 'null' && initData.AreaList !== null) {
                                this.area_id = initData.AreaList[0].area_id;
                            }
                        } else {
                            this.area_id = 0;
                        }
                    }
                }
                // 获取升降标号列表数据
                this.getGrade();
            },
        },
        // 混凝土
        gradeConcrete: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.gradeConcrete = val;
                this.gradeConcrete.forEach((item, index) => {
                    // this.$set(this.gradeDisArr, index, { disabledArr: [] });
                    item.editObj = {
                        disabledArr: [],
                        disabledSel: index === val.length - 1 ? false : true,
                    };
                    if (item.float_floor <= 'C25') {
                        item.float_type = 2;
                        this.gradeConcreteOption.forEach(opt => {
                            if (opt.name >= item.float_floor && opt.name <= 'C25') {item.editObj.disabledArr.push(opt.name);}
                        });
                    } else if (item.float_floor >= 'C35' ) {
                        item.float_type = 1;
                        this.gradeConcreteOption.forEach(opt => {
                            // if (opt.name >= 'C35') {this.gradeDisArr[index].disabledArr.push(opt.name);}
                            if (opt.name >= item.float_floor) {item.editObj.disabledArr.push(opt.name);}
                        });
                    }
                    if (item.float_upper) {
                        const idx = _.findIndex(this.gradeConcreteOption, d => {
                            return d.name === item.float_upper;
                        });
                        item.upper_code = idx > -1 ? this.gradeConcreteOption[idx].code : '';
                    }
                });
            },

        },
        // 砂浆
        gradeMortar: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.gradeMortar = val;
                const reg = /[^0-9.]/ig;
                this.gradeMortar.forEach((item, index) => {
                    item.editObj = {
                        disabledArr: [],
                        disabledSel: index === val.length - 1 ? false : true,
                    };
                    if (item.float_floor.replace(reg, '') <= 2.5) {
                        item.float_type = 2;
                        this.gradeMortarOption.forEach(opt => {
                            // eslint-disable-next-line max-len
                            if (Number(opt.name.replace(reg, '')) >= item.float_floor.replace(reg, '') && opt.name.replace(reg, '') <= 2.5) {item.editObj.disabledArr.push(opt.name);}
                        });
                    } else if (item.float_floor.replace(reg, '') >= 7.5 ) {
                        item.float_type = 1;
                        this.gradeMortarOption.forEach(opt => {
                            if (opt.name.replace(reg, '') >= Number(item.float_floor.replace(reg, ''))) {item.editObj.disabledArr.push(opt.name);}
                        });
                    }
                    if (item.float_upper) {
                        const idx = _.findIndex(this.gradeMortarOption, d => {
                            return d.name === item.float_upper;
                        });
                        item.upper_code = idx > -1 ? this.gradeMortarOption[idx].code : '';
                    }
                });
            },

        },
    },
    computed: {},
    methods: {
        renderHeader(h, { column }) { // h即为cerateElement的简写
            return h(
                'div',
                [
                    h('span', column.label),
                    h('i', {
                        class: 'el-icon-plus',
                        style: 'color:#1577d2;margin-left:5px;border:1px solid #1577d2;padding:.02rem .01rem .01rem',
                        on: {
                            click: this.addToGradeTwo, // 选中事件 添加砂浆
                        },
                    }),
                ],
            );
        },
        renderHeaderTwo(h, { column }) {
            return h(
                'div',
                [
                    h('span', column.label),
                    h('i', {
                        class: 'el-icon-plus',
                        style: 'color:#1577d2;margin-left:5px;border:1px solid #1577d2;padding:.02rem .01rem .01rem .015rem',
                        on: {
                            click: this.addToGrade, // 选中事件 添加混凝土
                        },
                    }),
                ],
            );
        },
        // 获取升降标号列表数据
        getGrade() {
            let areaIds;
            if (this.isRegions) {
                areaIds = this.newAreaid ? this.newAreaid : this.area_id;
            } else {
                areaIds = 0;
            }
            const history = this.currentData.historyId ? this.currentData.historyId + '/' : '0/';
            if (this.currentData.historyId === '') {return;}
            this.$axios
                .get('/interfaceApi/sale/price_config/get_price_label/' + history + areaIds)
                .then(res => {
                    // 升降标号数据集合
                    this.tabPageData = res;
                    this.tabPageDataHandle();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 处理数据
        tabPageDataHandle() {
            this.gradeConcrete = [];
            this.gradeMortar = [];
            if (this.tabPageData && this.tabPageData.length > 0) {
                this.tabPageData.forEach(item => {
                    if (item.float_group === 0) {
                        // 升降标号-混泥土数据集合
                        this.gradeConcrete.push(item);
                    } else {
                        // 升降标号-砂浆数据集合
                        this.gradeMortar.push(item);
                    }

                });
            }
            if (this.gradeConcrete.length < 1) {
                this.gradeConcrete = [];
                this.gradeConcrete.push(
                    {
                        id: '',
                        price_id: this.price_id,
                        area_id: this.area_id,
                        float_group: 0,
                        upper_code: '', // 上限编码
                        floor_code: this.gradeConcreteOption[0].code, // 下限编码
                        float_upper: '', // 右边的标号
                        float_floor: this.gradeConcreteOption[0].name, // 左边的标号
                        float_type: 1,
                        float_price: 0,
                    }
                );
            }
            if (this.gradeMortar.length < 1) {
                this.gradeMortar = [];
                this.gradeMortar.push(
                    {
                        id: '',
                        price_id: this.price_id,
                        area_id: this.area_id,
                        float_group: 1,
                        upper_code: '', // 上限编码
                        floor_code: this.gradeMortarOption[0].code, // 下限编码
                        float_upper: '', // 右边的标号
                        float_floor: this.gradeMortarOption[0].name, // 左边的标号
                        float_type: 1,
                        float_price: 0,
                    }
                );
            }
        },
        // 获取混泥土标号
        getConcreteGrad() {
            this.$axios
                .get('/interfaceApi/BaseInfo/dictionary_config/getlist?parent_code=004-PH0&type=0')
                .then(res => {
                    // 混泥土标号赋值
                    this.gradeConcreteOption = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取砂浆标号
        getMortarLabel() {
            this.$axios
                .get('/interfaceApi/BaseInfo/dictionary_config/getlist?parent_code=005-SJ0&type=0')
                .then(res => {
                    // 砂浆标号赋值
                    this.gradeMortarOption = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 删除混凝土升降标号
        deleteGrade(row, index) {
            if (!row.id) {
                this.gradeConcrete.splice(index, 1);
            } else {
                this.$axios
                    .delete('/interfaceApi/sale/price_config/delete_price_label/' + row.id)
                    .then(res => {
                        this.$message({
                            message: res,
                            type: 'success',
                        });
                        this.gradeConcrete.splice(index, 1);
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }

        },
        // 删除砂浆升降标号
        deleteGradeTwo(row, index) {
            if (!row.id) {
                this.gradeMortar.splice(index, 1);
            } else {
                this.$axios
                    .delete('/interfaceApi/sale/price_config/delete_price_label/' + row.id)
                    .then(res => {
                        this.$message({
                            message: res,
                            type: 'success',
                        });
                        this.gradeMortar.splice(index, 1);
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
        // 添加混凝土升降标号
        addToGrade() {
            let lastBh = '';
            let addBhLine = '';
            if (this.gradeConcrete.length !== 0) {
                lastBh = this.gradeConcreteOption.slice(-1)[0].name; // 混凝土最大标号
                addBhLine = this.gradeConcrete[this.gradeConcrete.length - 1].float_upper; // 点击添加当前行最大混凝土标号
            }
            if (this.gradeConcrete.length === 0) {
                this.gradeConcrete = [];
                this.gradeConcrete.push(
                    {
                        id: '',
                        price_id: this.price_id,
                        area_id: this.area_id,
                        float_group: 0,
                        upper_code: '', // 上限编码
                        floor_code: this.gradeConcreteOption[0].code, // 下限编码
                        float_upper: '', // 右边的标号
                        float_floor: this.gradeConcreteOption[0].name, // 左边的标号
                        float_type: 1,
                        float_price: 0,
                    }
                );
            } else if (addBhLine && addBhLine !== lastBh ) {
                this.deleteId = '';
                this.gradeConcrete = this.gradeConcrete.map(item => {
                    item.editObj.disabledSel = true;
                    return item;
                });
                const len = this.gradeConcrete.length;
                const lastObj = this.gradeConcrete[len - 1];
                const index = _.findIndex(this.gradeConcreteOption, d => {
                    return d.name === lastObj.float_upper;
                });
                const obj = {
                    id: '',
                    price_id: this.price_id,
                    area_id: this.area_id,
                    float_group: 0,
                    upper_code: '', // 上限编码
                    floor_code: this.gradeConcreteOption[index + 1].code, // 下限编码
                    float_upper: '', // 右边的标号
                    float_floor: this.gradeConcreteOption[index + 1].name, // 左边的标号
                    float_type: 1,
                    float_price: 0,
                };
                if (lastObj.float_upper === 'C25') {
                    obj.float_floor = 'C35';
                }
                this.gradeConcrete.push(obj);
            } else if (addBhLine === lastBh ) {
                this.$message.warning('混凝土编号已选择上限');
            } else {
                this.$message({
                    message: '请选择标号',
                    type: 'error',
                });
            }

        },
        // 添加砂浆
        addToGradeTwo() {
            this.deleteId = '';
            let lastBh = '';
            let addBhLine = '';
            // 修改
            if (this.gradeMortar.length !== 0) {
                lastBh = this.gradeMortarOption.slice(-1)[0].name; // 砂浆最大标号
                addBhLine = this.gradeMortar[this.gradeMortar.length - 1].float_upper; // 点击添加当前行最大砂浆标号
            }
            if (this.gradeMortar.length === 0) {
                this.gradeMortar = [];
                this.gradeMortar.push(
                    {
                        id: '',
                        price_id: this.price_id,
                        area_id: this.area_id,
                        float_group: 1,
                        upper_code: '', // 上限编码
                        floor_code: this.gradeMortarOption[0].code, // 下限编码
                        float_upper: '', // 右边的标号
                        float_floor: this.gradeMortarOption[0].name, // 左边的标号
                        float_type: 1,
                        float_price: 0,
                    }
                );
            } else if (addBhLine && addBhLine !== lastBh) {
                this.deleteId = '';
                this.gradeMortar = this.gradeMortar.map(item => {
                    item.editObj.disabledSel = true;
                    return item;
                });
                const len = this.gradeMortar.length;
                const lastObj = this.gradeMortar[len - 1];
                const index = _.findIndex(this.gradeMortarOption, d => {
                    return d.name === lastObj.float_upper;
                });
                const obj = {
                    id: '',
                    price_id: this.price_id,
                    area_id: this.area_id,
                    float_group: 1,
                    upper_code: '', // 上限编码
                    floor_code: this.gradeMortarOption[index + 1].code, // 下限编码
                    float_upper: '', // 右边的标号
                    float_floor: this.gradeMortarOption[index + 1].name, // 左边的标号
                    float_type: 1,
                    float_price: 0,
                };
                const reg = /[^0-9.]/ig;
                if (lastObj.float_upper.replace(reg, '') === '2.5') {
                    obj.float_floor = 'M7.5';
                }
                this.gradeMortar.push(obj);
            } else if (addBhLine === lastBh ) {
                this.$message.warning('砂浆编号已选择上限');
            } else {
                this.$message({
                    message: '请选择标号',
                    type: 'error',
                });
            }
        },
        // 保存标号
        preserveGrade(data, idx, status) {
            data.area_id = this.newAreaid;
            this.$axios
                .put('/interfaceApi/sale/price_config/modify_price_label', data)
                .then(res => {
                    if (res) {
                        if (status === 'tu') {
                            this.gradeConcrete[idx].id = res;
                        } else {
                            this.gradeMortar[idx].id = res;
                        }
                        this.$message({
                            message: '修改成功',
                            type: 'success',
                        });
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
    created() {
        // 期数id,区域id赋值
        if (this.currentData.initData.SalePriceInfo) {
            if (this.currentData.initData.SalePriceInfo !== 'null' && this.currentData.initData.SalePriceInfo !== null) {
                this.price_id = this.currentData.initData.SalePriceInfo.id;
                if (this.currentData.initData.SalePriceInfo.is_area) {
                    if (this.currentData.initData.AreaList !== 'null' && this.currentData.initData.AreaList !== null) {
                        this.area_id = this.currentData.initData.AreaList[0].area_id;
                    }
                }
            }
        }
        // 拉去混泥土标号集合
        this.getConcreteGrad();
        // 拉取砂浆标号数据
        this.getMortarLabel();
    },
    mounted() {},
};
</script>
<style lang="stylus">
.Grade
    input:disabled
        color #999999 !important
    .cell
        display flex
        .biaohao
            font-size .14rem
            color #999999
            background  #eee
            width .5rem
            height .2rem
            border-radius .04rem
            display: flex;
            justify-content: center;
            align-items: center;
    .has-gutter tr th:nth-child(3) .cell
        line-height .25rem
        height .25rem
        div
            line-height .25rem
            height .25rem
    .gradeMortar
        .el-select
            width 1.1rem
    .el-table
        .el-table__body
            table-layout auto
        .cell
            white-space nowrap
</style>